import React from 'react'
import { graphql } from 'gatsby'
import { Fade, Zoom } from 'react-reveal'
import classNames from 'classnames'

import SEO from '../components/SEO'
import Header from '../components/Header'
import Footer from '../components/Footer'
import { RowLayout } from '../components/layout'

import PageContent from '../old/pages/GetRadio'
import '../assets/styles.css'
import appStoreBadge from '../old/assets/old-images/home/app-store-badge.svg'
import googlePlayBadge from '../old/assets/old-images/home/google-play-badge.png'
import graceDigitalLogo from '../old/assets/old-images/get-radio/grace-digital-logo@2x.png'

export default ({ data }) => {
  const headerContent = (
    <RowLayout
      isResponsive
      justifyContent={'center'}
      itemSpacing={'2rem'}
      className={classNames('get-radio')}
    >
      <Fade duration={700} delay={300}>
        <Zoom duration={700} delay={100}>
          <a
            className={classNames('platform-link')}
            href="https://itunes.apple.com/us/app/live365-radio-music-talk/id1228431134?mt=8"
          >
            <div
              className={classNames(
                'platform-panel',
                'with-background-image',
                'app-store'
              )}
            >
              <img src={appStoreBadge} alt="Download on the App Store" />
            </div>
          </a>
        </Zoom>
      </Fade>

      <Fade duration={700} delay={300}>
        <Zoom duration={700} delay={200}>
          <a
            className={classNames('platform-link')}
            href="https://play.google.com/store/apps/details?id=com.live365&hl=en"
          >
            <div
              className={classNames(
                'platform-panel',
                'with-background-image',
                'google-play'
              )}
            >
              <img src={googlePlayBadge} alt="Get it on Google Play" />
            </div>
          </a>
        </Zoom>
      </Fade>

      <Fade duration={700} delay={300}>
        <Zoom duration={700} delay={400}>
          <div
            className={classNames(
              'platform-panel',
              'with-background-image',
              'grace-digital'
            )}
          >
            <img src={graceDigitalLogo} alt="Grace Digital" />
          </div>
        </Zoom>
      </Fade>
    </RowLayout>
  )
  return (
    <React.Fragment>
      <SEO title={'Get Internet Radio'} />
      <Header
        title={'Listen to Live365 Radio Everywhere'}
        subtitle={
          'Live365 stations are currently available on these platforms, with more coming soon.'
        }
        lightOnDark={true}
        barStyle={'overlay'}
        showCallToActionButton={true}
        background={{
          image: data.hero.edges[0].node.childImageSharp.fluid,
          withOverlay: false
        }}
        children={headerContent}
      />
      <PageContent />
      <Footer />
    </React.Fragment>
  )
}

export const query = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    hero: allFile(filter: { relativePath: { eq: "hero-getradio.jpg" } }) {
      edges {
        node {
          childImageSharp {
            fluid(maxWidth: 2880, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`
