import React from 'react'
import classNames from 'classnames'

import Page from '../templates/Page'
import Contact from '../components/Contact'

import './get-radio.css'

class GetRadio extends React.Component {
  render() {
    return (
      <Page
        className={classNames('get-radio')}
        headerTitle="Listen to Live365 Radio Everywhere"
        headerSubtitle="Live365 stations are currently available on these platforms, with more coming soon."
        withHeaderBackgroundImage
        header={null}
      >
        <Contact
          className={classNames('page-section')}
          title="Missing a platform?"
          description="Contact us to learn more about Live365 Radio’s integration and API services."
        />
      </Page>
    )
  }
}

export default GetRadio
